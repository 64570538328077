import React, { useContext } from 'react';
import { UserContext } from '../UserProfileContext';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import FormHelperText from '@mui/material/FormHelperText';

const DashboardEngTOEFL = () => {
  const { userDataFetched, setUserDataFetched, utilityData } = useContext(UserContext);
  return (
    <div className='mt-3'>
      <div className="row eng-test-form">
        <div className="col-12">
          <label className='py-2'><b>Composite Score</b></label>
          <OutlinedInput
            type='number'
            id="eng-comp-score"
            endAdornment={<InputAdornment position="end">/{utilityData.toefl.composite}</InputAdornment>}
            aria-describedby="eng-comp-score-helper-text"
            inputProps={{
              'aria-label': 'eng-comp-score',
            }}
            className='w-100'
            size='small'
            value={userDataFetched['aboutDetails']['englishTestDetails']['toefl']['compositeScore']}
            onChange={(e) => {
              const updatedUserData = { ...userDataFetched };
              updatedUserData.aboutDetails.englishTestDetails.toefl.compositeScore = parseInt(e.target.value, 10);
              updatedUserData.aboutDetails.englishTestDetails.notTaken = false;
              setUserDataFetched(updatedUserData);
            }}
          />
          <FormHelperText id="eng-comp-score-helper-text" className='eng-helper-text'>
            <span className="material-symbols-outlined mr-1">
              info
            </span> Enter the final score or the score you're expecting
          </FormHelperText>
        </div>
        <div className="col-12 mt-3">
          <label className='pt-2'><b>Test Breakup</b><span className="text-muted"> (Optional)</span></label>
          <hr />
        </div>
        <div className="col-6 mt-1">
          <label className='py-2'><b>Reading</b></label>
          <OutlinedInput
            type='number'
            id="eng-reading-score"
            endAdornment={<InputAdornment position="end">/{utilityData.toefl.reading}</InputAdornment>}
            aria-describedby="eng-reading-score-helper-text"
            inputProps={{
              'aria-label': 'eng-reading-score',
            }}
            className='w-100'
            size='small'
            value={userDataFetched['aboutDetails']['englishTestDetails']['toefl']['readingScore']}
            onChange={(e) => {
              const updatedUserData = { ...userDataFetched };
              updatedUserData.aboutDetails.englishTestDetails.toefl.readingScore = parseInt(e.target.value, 10);
              updatedUserData.aboutDetails.englishTestDetails.notTaken = false;
              setUserDataFetched(updatedUserData);
            }}
          />
        </div>
        <div className="col-6 mt-1">
          <label className='py-2'><b>Writing</b></label>
          <OutlinedInput
            type='number'
            id="eng-writing-score"
            endAdornment={<InputAdornment position="end">/{utilityData.toefl.writing}</InputAdornment>}
            aria-describedby="eng-writing-score-helper-text"
            inputProps={{
              'aria-label': 'eng-writing-score',
            }}
            className='w-100'
            size='small'
            value={userDataFetched['aboutDetails']['englishTestDetails']['toefl']['writingScore']}
            onChange={(e) => {
              const updatedUserData = { ...userDataFetched };
              updatedUserData.aboutDetails.englishTestDetails.toefl.writingScore = parseInt(e.target.value, 10);
              updatedUserData.aboutDetails.englishTestDetails.notTaken = false;
              setUserDataFetched(updatedUserData);
            }}
          />
        </div>
        <div className="col-6 mt-1">
          <label className='py-2'><b>Speaking</b></label>
          <OutlinedInput
            type='number'
            id="eng-speaking-score"
            endAdornment={<InputAdornment position="end">/{utilityData.toefl.speaking}</InputAdornment>}
            aria-describedby="eng-speaking-score-helper-text"
            inputProps={{
              'aria-label': 'eng-speaking-score',
            }}
            className='w-100'
            size='small'
            value={userDataFetched['aboutDetails']['englishTestDetails']['toefl']['speakingScore']}
            onChange={(e) => {
              const updatedUserData = { ...userDataFetched };
              updatedUserData.aboutDetails.englishTestDetails.toefl.speakingScore = parseInt(e.target.value, 10);
              updatedUserData.aboutDetails.englishTestDetails.notTaken = false;
              setUserDataFetched(updatedUserData);
            }}
          />
        </div>
        <div className="col-6 mt-1">
          <label className='py-2'><b>Listening</b></label>
          <OutlinedInput
            type='number'
            id="eng-listening-score"
            endAdornment={<InputAdornment position="end">/{utilityData.toefl.listening}</InputAdornment>}
            aria-describedby="eng-listening-score-helper-text"
            inputProps={{
              'aria-label': 'eng-listening-score',
            }}
            className='w-100'
            size='small'
            value={userDataFetched['aboutDetails']['englishTestDetails']['toefl']['listeningScore']}
            onChange={(e) => {
              const updatedUserData = { ...userDataFetched };
              updatedUserData.aboutDetails.englishTestDetails.toefl.listeningScore = parseInt(e.target.value, 10);
              updatedUserData.aboutDetails.englishTestDetails.notTaken = false;
              setUserDataFetched(updatedUserData);
            }}
          />
        </div>
        <div className="col-md-6">
          <label className='pt-2'><b>Date you appeared for the test</b><span className="text-muted"> (Optional)</span></label>
          <OutlinedInput
            type="date"
            id="eng-exam-date"
            size='small'
            value={userDataFetched['aboutDetails']['englishTestDetails']['toefl']['dateTaken']}
            onChange={(e) => {
              const updatedUserData = { ...userDataFetched };
              updatedUserData.aboutDetails.englishTestDetails.toefl.dateTaken = e.target.value;
              updatedUserData.aboutDetails.englishTestDetails.notTaken = false;
              setUserDataFetched(updatedUserData);
            }}
            className='w-100' />
        </div>
      </div>
    </div >
  )
}

export default DashboardEngTOEFL
