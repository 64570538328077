import React, { useState } from "react";
import { useSearchParams } from 'react-router-dom';
import { useEffect } from 'react';
import axios from 'axios';
import ErrorPage from "../../pages/ErrorPage";
import Swal from 'sweetalert2';

const ResetPasswordC1 = () => {
    const [searchParams] = useSearchParams();
    const [inputRecoveryPassword, setInputRecoveryPassword] = useState("");
    const [token, setToken] = useState("");
    const inputRecoveryPasswordHandler = (e) => {
        setInputRecoveryPassword(e.target.value);
    }
    const recoveryPasswordSubmitButton = () => {
        const swalLoad = Swal.fire({
            title: 'Resetting Password',
            html: 'Please wait...',
            allowEscapeKey: false,
            allowOutsideClick: false,
            didOpen: () => {
                Swal.showLoading()
            }
        });
        const headers = {
            'Authorization': 'Bearer ' + token
        };
        const data = {
            "password": inputRecoveryPassword
        }
        axios.post("https://zyeero-server.onrender.com/api/useractions/updatePassword", data, { headers })
            .then(response => {
                swalLoad.close()
                console.log(response);
                Swal.fire({
                    title: 'SUCCESS',
                    text: response.data.message,
                    icon: 'success',
                    confirmButtonText: 'OK',
                }).then(() => {
                    window.location.href = "/"
                });
            })
            .catch(error => {
                console.log(error.response.data);
                Swal.fire({
                    title: 'PASSWORD RESET FAILED',
                    text: error.response.data.message,
                    icon: 'error',
                    confirmButtonText: 'TRY AGAIN',
                })
            });
    }
    useEffect(() => {
        setToken(searchParams.get('token'));
    }, [])
    if (token !== null) {
        return (
            <div className='row'>
                <form>
                    <div className="form-group mb-3" id="reset-password-id">
                        <label for="exampleInputPassword2"><b>Reset Password</b></label>
                        <input type="password" className="form-control" id="recoveryPassword" placeholder="Password" onChange={inputRecoveryPasswordHandler} required />
                    </div>
                </form>
                <button type="button" className="btn btn-primary mt-3 ml-4" id="recovery-pass-submit-button" onClick={recoveryPasswordSubmitButton} >Reset Account</button>
            </div>
        )
    } else {
        return (
            <ErrorPage />
        )
    }
}

export default ResetPasswordC1
