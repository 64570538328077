import React, { useContext } from 'react';
import { TextField } from '@material-ui/core';
import '../../css/Signup/SignUpForm.css';
import { multiStepContext } from './SignupStepContext';

const SignupForm1 = () => {
  const { setStep, userData, setUserData } = useContext(multiStepContext);
  return (
    <div className='singunp-step-form'>
      <div className="row form-field-row mx-3">
        <h3 className='my-5'  >Personal Information</h3>

        {/* First Name */}
        <TextField size="small" className='my-2 form-input-field' label="First Name" variant="outlined" value={userData['firstname']} onChange={(e)=>setUserData({...userData,"firstname":e.target.value})} required/>

        {/* Last Name */}
        <TextField size="small" className='my-2 form-input-field' label="Last Name" variant="outlined" value={userData['lastname']} onChange={(e)=>setUserData({...userData,"lastname":e.target.value})} required/>

        {/* Username */}
        <TextField size="small" className='my-2 form-input-field' label="Username" variant="outlined" value={userData['username']} onChange={(e)=>setUserData({...userData,"username":e.target.value})} required/>

        {/* Email Address */}
        <TextField size="small" className='my-2 form-input-field' label="Email" variant="outlined" value={userData['email']} onChange={(e)=>setUserData({...userData,"email":e.target.value})} required/>

        {/* Next Button */}
        <button type='button' className='my-4 btn btn-success' onClick={()=>{setStep(2)}} disabled={(!userData['firstname']) || (!userData['lastname']) || (!userData['username']) || (!userData['email'])}>Next</button>
      </div>
    </div>
  )
}

export default SignupForm1
