import React, { useContext } from 'react';
import { UserContext } from '../UserProfileContext';
import DashboardAptGRE from './DashboardAptGRE';
import DashboardAptGMAT from './DashboardAptGMAT';

const DashboardAptitude = () => {
  const { setDashboardStep, currentAptitudeStep, setAptitudeStep, userDataFetched, setUserDataFetched } = useContext(UserContext);
  function showDashboardAptitudeStep(step) {
    switch (step) {
      case 1:
        return <DashboardAptGRE />
      case 2:
        return <DashboardAptGMAT />
      case 3:
        return <></>
    }
  }
  return (
    <div className='dashboard-aptitude-row w-100 mt-4'>
      <div className="row">
        <b>Aptitude Test taken</b>
      </div>
      <div className="english-test-options-row mt-3">
        <div className={`my-2 mr-2 btn btn-english-test ${currentAptitudeStep === 1 ? 'english-active' : ''}`} onClick={() => { setAptitudeStep(1) }}>GRE</div>
        <div className={`my-2 mx-2 btn btn-english-test ${currentAptitudeStep === 2 ? 'english-active' : ''}`} onClick={() => { setAptitudeStep(2) }}>GMAT</div>
        <div
          className={`my-2 mx-2 btn btn-english-test ${currentAptitudeStep === 3 ? 'english-active' : ''}`}
          onClick={() => {
            setAptitudeStep(3);
            const updatedUserData = { ...userDataFetched };
            updatedUserData.aboutDetails.aptitudeTestDetails.notTaken = true;
            setUserDataFetched(updatedUserData);
          }}>
          Not Taken
        </div>
      </div>
      {showDashboardAptitudeStep(currentAptitudeStep)}<br />
      <div className="next-prev-btn">
        <div className='col-1'>
          <span className="material-symbols-outlined mt-4" onClick={() => { setDashboardStep(2) }}>
            keyboard_double_arrow_left
          </span>
        </div>
        <div className='col-1'>
          <span className="material-symbols-outlined mt-4" onClick={() => { setDashboardStep(4) }}>
            keyboard_double_arrow_right
          </span>
        </div>
      </div>
    </div>
  )
}

export default DashboardAptitude
