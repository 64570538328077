import React, { useContext } from 'react';
import { TextField } from '@material-ui/core';
import '../../css/Signup/SignUpForm.css';
import { multiStepContext } from './SignupStepContext';
import Autocomplete from '@mui/material/Autocomplete';

const SignupForm4 = () => {
  const { setStep, userData, setUserData, majorOptions, inputValue, handleInputChange, filterOptions, getOptionDisabled, degreeOptions } = useContext(multiStepContext);
  return (
    <div className='singunp-step-form'>
      <div className="row form-field-row mx-3">

        {/* Degree Option */}
        <label className="mt-3">
          <b>Degree you're planning for. </b><span className="text-muted">(Not the one you have)</span>
        </label>
        <div className="row degree-planning-options ml-1">
          {degreeOptions.map((degreeOption, index) => (
            <div key={index}
              className={`col-sm degree-planning-option ${userData['degree'] === degreeOption.degree ? 'selected-degree' : ''}`}
              onClick={() => {
                setUserData({ ...userData, "degree": degreeOption.degree })
              }}>
              <img src={require(`../../assets/${degreeOption.degree_img}`)} className="degree-icons mt-2" alt="" />
              <div className='my-2'>{degreeOption.degree}</div>
            </div>
          ))}
        </div>

        {/* Preferred Major Options */}
        <label className='mt-3'><b>Your Preferred Major</b></label>
        <Autocomplete
          multiple
          className='form-input-field mt-2'
          size="small"
          options={majorOptions}
          getOptionLabel={(option) => option.label}
          value={userData['major_options']}
          inputValue={inputValue}
          limitTags={1}
          onInputChange={handleInputChange}
          filterOptions={filterOptions}
          getOptionDisabled={getOptionDisabled}
          onChange={(e, major_options) => { setUserData({ ...userData, "major_options": major_options }) }}
          renderInput={(params) => (
            <TextField {...params} label="Select Majors" variant="outlined" fullWidth />
          )}
        />
        <small className="text-muted">Choose upto 3 Majors</small>

        {/* Next and Back button rows */}
        <div className="row signup-form-btn-row my-4">
          {/* Back Button */}
          <button type='button' className='btn btn-primary col-3 mx-2' onClick={() => { setStep(3) }}>Back</button>
          {/* Next Button */}
          <button type='button' className='btn btn-success col-3' onClick={() => { setStep(5) }} disabled={((userData['major_options']).length === 0) || !userData['degree']}>Next</button>
        </div>

      </div>
    </div >
  )
}

export default SignupForm4
