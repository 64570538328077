import React, { useState } from "react";
import { HashLink as Link } from "react-router-hash-link";
import animationData from '../../lotties/edu-loan-2.json';
import Lottie from 'react-lottie-player';
import axios from 'axios';
import Swal from 'sweetalert2';

const LoginModal = ({ handleClose, handleSignInShow }) => {
    const handleClick = (e) => {
        const swalLoad = Swal.fire({
            title: 'Logging In',
            html: 'Please wait...',
            allowEscapeKey: false,
            allowOutsideClick: false,
            didOpen: () => {
                Swal.showLoading()
            }
        });
        const data =
        {
            "email": inputEmail,
            "password": inputPassword
        }
        axios.post("https://zyeero-server.onrender.com/api/initUser/login", data)
            .then(response => {
                swalLoad.close();
                console.log(response.status);
                // console.log(response.data.token);
                localStorage.setItem('token',response.data.token);
                console.log(localStorage.getItem('token'));
                // Swal.fire({
                //     title: 'SUCCESS',
                //     text: response.data.message,
                //     icon: 'success',
                //     confirmButtonText: 'OK',
                // }).then(() => {
                    window.location.href = "/#profile-dashboard"
                // });
            })
            .catch(error => {
                console.log(error.response);
                Swal.fire({
                    title: 'LOGIN FAILED',
                    text: error.response.data.message,
                    icon: 'error',
                    confirmButtonText: 'TRY AGAIN',
                });
            });
    }
    const [inputEmail, setInputEmail] = useState("");
    const [inputPassword, setInputPassword] = useState("");
    const [inputRecoveryEmail, setInputRecoveryEmail] = useState("");


    const inputEmailHandler = (e) => {
        setInputEmail(e.target.value);
    }
    const inputPasswordHandler = (e) => {
        setInputPassword(e.target.value);
    }
    const inputRecoveryEmailHandler = (e) => {
        setInputRecoveryEmail(e.target.value);
    }

    const submitButton = () => {
        console.log(inputEmail);
        console.log(inputPassword);
        handleClick();
    }
    const handlePasswordRecovery = () => {
        document.getElementById('login-modal-col-id').style.display = "none";
        document.getElementById('recovery-modal-col-id').style.display = "flex";
    }

    const recoverySubmitButton = () => {
        const swalLoad = Swal.fire({
            title: 'Sending Verification Mail',
            html: 'Please wait...',
            allowEscapeKey: false,
            allowOutsideClick: false,
            didOpen: () => {
                Swal.showLoading()
            }
        });
        console.log(inputRecoveryEmail);
        const data = {
            "email": inputRecoveryEmail
        }
        axios.post("https://zyeero-server.onrender.com/api/initUser/sendResetPasswordLink", data)
            .then(response => {
                swalLoad.close();
                console.log(response);
                Swal.fire({
                    title: 'SUCCESS',
                    text: response.data.message,
                    icon: 'success',
                    confirmButtonText: 'OK',
                }).then(() => {
                    window.location.href = "/"
                });
            })
            .catch(error => {
                console.log(error.response.data);
                Swal.fire({
                    title: 'VERIFICATION FAILED',
                    text: error.response.data.message,
                    icon: 'error',
                    confirmButtonText: 'TRY AGAIN',
                })
            });
    }

    return (
        <>
            <div className="row">
                <div className="col-md-6 login-modal-col" id="login-modal-col-id">
                    <h3 className="ml-4 login-now-heading">LOGIN NOW</h3>
                    <div className="login-form-fields ml-4 mt-3">
                        <form id="login-form-id">
                            <div className="form-group mb-3">
                                <label for="exampleInputEmail1"><b>Email Address</b></label>
                                <input type="email" className="form-control" id="loginInputEmail" aria-describedby="emailHelp" placeholder="abc@example.com" onChange={inputEmailHandler} autoFocus required />
                            </div>
                            <div className="form-group mb-3">
                                <label for="exampleInputPassword1"><b>Password</b></label>
                                <input type="password" className="form-control" id="exampleInputPassword1" placeholder="Password" onChange={inputPasswordHandler} required />
                            </div>
                        </form>
                    </div>
                    <button type="button" className="btn btn-primary mt-3 ml-4" id="submit-button" onClick={submitButton} >Submit</button>
                    <div className="text-center forgot-password" id="forgot-password-link-id" onClick={handlePasswordRecovery}><small>Forgot Password</small></div>
                    <Link to="/signup"><div className="text-center sign-in-link mt-2"><small>New to Zyeero? Register Now</small></div></Link>
                </div>
                <div className="col-md-6 login-modal-col" id="recovery-modal-col-id">
                    <h3 className="ml-4 login-now-heading">Recover Your Account</h3>
                    <div className="login-form-fields ml-4 mt-3">
                        <form id="reset-password-form-id">
                            <div className="form-group mb-3" id="reset-mail-id">
                                <label for="exampleInputEmail2"><b>Registered Email Address</b></label>
                                <input type="email" className="form-control" id="recoveryInputEmail" aria-describedby="emailHelp" placeholder="abc@example.com" onChange={inputRecoveryEmailHandler} autoFocus required />
                            </div>
                        </form>
                    </div>
                    <button type="button" className="btn btn-primary mt-3 ml-4" id="recovery-submit-button" onClick={recoverySubmitButton} >Send Verification Mail</button>
                    <Link to="/signup"><div className="text-center sign-in-link mt-2"><small>New to Zyeero? Register Now</small></div></Link>
                </div>
                <div className="col-md-6 sign-up-col">
                    <Lottie
                        rendererSettings={{ preserveAspectRatio: 'xMidYMid slice' }}
                        loop
                        animationData={animationData}
                        play
                        style={{ height: '70vh', width: '100%', borderRadius: '10px', color: '#4f07cc' }}
                    />
                    <span className="material-symbols-outlined" onClick={handleClose}>
                        close
                    </span>
                </div>
            </div>
        </>
    )
}

export default LoginModal
