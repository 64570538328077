import React from 'react'
import { HashLink as Link } from "react-router-hash-link";
import '../../css/Home/Home_C11.css'
import {
    MDBFooter,
    MDBContainer,
    MDBCol,
    MDBRow
} from 'mdb-react-ui-kit';
const Home_C11 = () => {
    return (
        <MDBFooter className='row bg-secondary text-white'>
            <MDBContainer className='p-4'>
                <MDBRow className='mt-3'>
                    <MDBCol lg="6" md="12" className='mb-4 mb-md-0'>
                        <h5 className='text-uppercase'>Zyeero</h5>

                        <p>
                            Some quick example text to build on the card title and make up the bulk of the card's content.
                        </p>
                    </MDBCol>

                    <MDBCol md="2" lg="2" xl="2" className='mx-auto mb-4'>
                        <h6 className='text-uppercase fw-bold mb-4'>Suggested Links</h6>
                        <hr
                            style={{ borderTop: "1px solid lightgrey" }}
                        ></hr>
                        <p>
                            <Link to='/' className='text-reset'>
                                Home
                            </Link>
                        </p>
                        <p>
                            <Link to='/' className='text-reset'>
                                Services
                            </Link>
                        </p>
                        <p>
                            <Link to='/' className='text-reset'>
                                Terms of Service
                            </Link>
                        </p>
                        <p>
                            <Link to='/' className='text-reset'>
                                Privacy Policy
                            </Link>
                        </p>
                    </MDBCol>

                    <MDBCol md="3" lg="2" xl="2" className='mx-auto mb-4'>
                        <h6 className='text-uppercase fw-bold mb-4'>Most Popular</h6>
                        <hr
                            style={{ borderTop: "1px solid lightgrey" }}
                        ></hr>
                        <p>
                            <Link to='/' className='text-reset'>
                                Education Loan
                            </Link>
                        </p>
                        <p>
                            <Link to='/' className='text-reset'>
                                Visa application help
                            </Link>
                        </p>
                        <p>
                            <Link to='/' className='text-reset'>
                                Mock visa interview
                            </Link>
                        </p>
                        <p>
                            <Link to='/' className='text-reset'>
                                Complete Visa application
                            </Link>
                        </p>
                        <p>
                            <Link to='/' className='text-reset'>
                                Complete university application
                            </Link>
                        </p>
                    </MDBCol>
                </MDBRow>
            </MDBContainer>
            <div className='text-center p-4' style={{ backgroundColor: 'rgba(0, 0, 0, 0.05)' }}>
                © 2023 Copyright:&nbsp;
                <a className='text-reset fw-bold' href=''>
                    Zyeero
                </a>
            </div>
        </MDBFooter>
    )
}

export default Home_C11
