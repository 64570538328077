import React from "react";
import "./TabCard.css";
import { Button } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import HouseIcon from "@mui/icons-material/House";
import Stack from "@mui/material/Stack";

function TabCard(props) {
  let imageSource = props.imageSource;
  return (
    <div className="tab-card">
      <div className="tab-card-info">
        <Stack direction="row" spacing={1}>
          <IconButton
            className="feature-tab-card-home-button"
            aria-label="fingerprint"
            color="secondary"
          >
            <HouseIcon />
          </IconButton>
        </Stack>
        <h1 className="feature-tabs-title-1">{props.title1}</h1>
        <h2 className="feature-tabs-title-2">{props.title2}</h2>
        <p className="feature-tabs-description">{props.description}</p>
        <Button variant="contained" className="feature-tab-card-cta-button">
          Get Started
        </Button>
      </div>
      <div className="tab-card-image">
        <img src={require('../../assets/featureTabImage.png')} />
      </div>
    </div>
  );
}

export default TabCard;
