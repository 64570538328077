import React from "react";

import "./CarouselCard.css";

function CarouselCard(props) {
  return (
    <>
      <div class="card">
        <div class="media media--2-3">
          <div class="primary-title">
            <div class="primary-text">
              {props.cardTitle}
            </div>
          </div>
          <img
            src={props.imageSouce}
            alt=""
            className="carousel-card-image"
          />{" "}
        </div>
      </div>
    </>
  );
}

export default CarouselCard;
