import { HashRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import Home from './pages/Home';
import Signup from './pages/Signup';
import ResetPassword from './pages/ResetPassword';
import UserProfileDashboard from './pages/UserProfileDashboard';
import SignupStepContext from './components/Signup/SignupStepContext';
import UserProfileContext from './components/UserProfileDashboard/UserProfileContext';
import DashboardForm from './components/UserProfileDashboard/dashboard/DashboardForm';
import Planner from './components/UserProfileDashboard/planner/Planner';
import University from './components/UserProfileDashboard/universities/University';
import Counsellor from './components/UserProfileDashboard/counsellor/Counsellor';

function App() {
  return (
    <div className="App container-fluid">
      <Router>
        <Routes>
          <Route path="/" element={<Home />} exact></Route>
          <Route path="/signup" element={<SignupStepContext><Signup /></SignupStepContext>}></Route>
          <Route path="/resetPassword" element={<ResetPassword />}></Route>
          <Route path="/profile-dashboard" element={<UserProfileContext><UserProfileDashboard /></UserProfileContext>}>
            <Route path="" element={<DashboardForm />} />
            <Route path="planner" element={<Planner />} />
            <Route path="universities" element={<University />} />
            <Route path="counselling" element={<Counsellor />} />
          </Route>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
