import React from "react";

import "./BlogCard.scss";
import { EcoSharp } from "@material-ui/icons";

function BlogCard(props) {
  return (
    <div>
      <div class="row">
        <div class="example-2 blog-card">
          <div
            className="wrapper"
            style={{ background: `url(${props.imageSource}) center / cover no-repeat` }}
          >
            <div class="data">
              <div class="content">
                <span class="author">{props.author}</span>
                <h1 class="title">
                  <a href="#">{props.title}</a>
                </h1>
                <p class="description">{props.description}</p>
                <a href="#" class="button">
                  Read more
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BlogCard;
