import React, { useState, useContext } from "react";
import SignupForm1 from '../components/Signup/SignupForm1';
import SignupForm2 from '../components/Signup/SignupForm2';
import SignupForm3 from '../components/Signup/SignupForm3';
import SignupForm4 from '../components/Signup/SignupForm4';
import SignupForm5 from '../components/Signup/SignupForm5';
import SignupForm6 from '../components/Signup/SignupForm6';
import '../css/Signup/SignUpForm.css';
import { Stepper, StepLabel, Step } from '@material-ui/core';
import { Modal } from "react-bootstrap";
import LoginModal from "../components/Login/LoginModal";
import { multiStepContext } from "../components/Signup/SignupStepContext";
import Home_C11 from "../components/Home/Home_C11";

const Signup = () => {

  const { currentStep } = useContext(multiStepContext);
  const [showModal, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  function showStep(step) {
    document.title = 'Zyeero | Sign Up'
    switch (step) {
      case 1:
        return <SignupForm1 />
      case 2:
        return <SignupForm2 />
      case 3:
        return <SignupForm3 />
      case 4:
        return <SignupForm4 />
      case 5:
        return <SignupForm5 />
      case 6:
        return <SignupForm6 />
    }
  }
  return (
    <>
      <div className='row signup-row'>
        <div className="col-md-6 sign-up-mit">
          <h2 className='text-white display-4'>ZYEERO</h2>
          <h3 className="text-white mt-3 display-6 text-center">Get in touch with experts for your dream college</h3>
        </div>
        <div className="col-md-6 signin-forms">
          <Stepper className='signin-stepper' activeStep={currentStep - 1} orientation='horizontal'>
            <Step>
              <StepLabel></StepLabel>
            </Step>
            <Step>
              <StepLabel></StepLabel>
            </Step>
            <Step>
              <StepLabel></StepLabel>
            </Step>
            <Step>
              <StepLabel></StepLabel>
            </Step>
            <Step>
              <StepLabel></StepLabel>
            </Step>
            <Step>
              <StepLabel></StepLabel>
            </Step>
          </Stepper>
          {showStep(currentStep)}
          <div className="login-now-from-signup mt-3 text-center" onClick={handleShow}><span>Already have an Account?<b>Login Here</b></span></div>
          <hr className="hr-line" />
          <Modal show={showModal} onHide={handleClose} size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered>
            <LoginModal
              handleClose={handleClose}
            />
          </Modal>
        </div>
      </div>
      <Home_C11/>
    </>
  )
}

export default Signup
