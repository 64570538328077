import * as React from "react";
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import { useTheme } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import TabCard from "../UIComponents/TabCard";

import "../../css/Home/FeatureTabs.css";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box className="feature-tab-box" sx={{ p: 4 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

export default function FeatureTabs() {
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  return (
    <div className="feature-tabs-section">
      <Box
        className="feature-tabs-div"
        sx={{ bgcolor: "background.paper", width: "70%" }}
      >
        <AppBar className="feature-tab-header-box" position="static">
          <Tabs
            className="feature-tabs"
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="black"
            variant="fullWidth"
            aria-label="full width tabs example"
          >
            <Tab className="feature-tab" label="Visa Help" />
            <Tab
              className="feature-tab"
              label="Loan Assistance"
              {...a11yProps(1)}
            />
            <Tab className="feature-tab" label="Exam Prep" {...a11yProps(2)} />
            <Tab
              className="feature-tab"
              label="Move Aborad"
              {...a11yProps(3)}
            />
          </Tabs>
        </AppBar>
        <SwipeableViews
          axis={theme.direction === "rtl" ? "x-reverse" : "x"}
          index={value}
          onChangeIndex={handleChangeIndex}
          className="swipeable-views"
        >
          <TabPanel
            className="feature-tab-panel"
            value={value}
            index={0}
            dir={theme.direction}
          >
            <TabCard
              title1="Visa Help"
              title2="to accomodation"
              description="Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled.
                        Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled."
              imageSource="../../assets/featureTabImage.png"
            />
          </TabPanel>
          <TabPanel
            className="feature-tab-panel"
            value={value}
            index={1}
            dir={theme.direction}
          >
            <TabCard
              title1="Loan Assistance"
              title2="assissting with loans"
              description="Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled.
Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled."
            />
          </TabPanel>
          <TabPanel
            className="feature-tab-panel"
            value={value}
            index={2}
            dir={theme.direction}
          >
            <TabCard
              title1="Exam Prep"
              title2="preparing you for exams"
              description="Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled.
Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled."
            />
          </TabPanel>
          <TabPanel
            className="feature-tab-panel"
            value={value}
            index={3}
            dir={theme.direction}
          >
            <TabCard
              title1="Move Abroad"
              title2="helping your prep for exams"
              description="Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled.
Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled."
            />
          </TabPanel>
        </SwipeableViews>
      </Box>
    </div>
  );
}
