import React, { useState } from "react";
import { HashLink as Link } from "react-router-hash-link";
import "../../css/Home/Hero.css";
import { Modal } from "react-bootstrap";
import LoginModal from "../Login/LoginModal";
import Button from "@mui/material/Button";

const Hero = () => {
  const [showModal, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  // const [showSignInModal, setShowSignIn] = useState(false);
  // const handleSignInShow = () => setShowSignIn(true);
  // const handleSignInClose = () => setShowSignIn(false);

  return (
    <div>
      <nav className="navbar navbar-expand-lg bg-body-tertiary">
        <div className="container-fluid">
          <a className="navbar-brand" href="#">
            <span className="px-5">ZYEERO</span>
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarTogglerDemo02"
            aria-controls="navbarTogglerDemo02"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarTogglerDemo02">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              <li className="nav-item active">
                <a className="nav-link" href="#">
                  Exam Preparations
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#">
                  Explore Universities
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#">
                  Application Assistance
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#">
                  Education Loan
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#">
                  Visa Interviews
                </a>
              </li>
            </ul>
            <form className="form-inline my-2 my-lg-0 mr-3">
              <Button
                className="btn login-btn mr-sm-2"
                type="button"
                onClick={handleShow}
              >
                Login
              </Button>
              <Link to="/signup">
                <button className="btn my-2 my-sm-0 sign-up-btn" type="button">
                  Register
                </button>
              </Link>
            </form>
          </div>
        </div>
      </nav>
      <div className="hero-background row header-row">
        <div className="hero-text">
          <p>Start your journey to</p>
          <p>Study Abroad</p>
          <Button variant="contained" className="primary-button">
            Get Started
          </Button>
        </div>
        {/* <div className="get-started-btn my-4">
                    <button className="btn" type="button" onClick={handleShow}>Get Started</button>
                    <div className="learn-more-icon mx-5">
                        Learn More<span className="material-symbols-outlined mx-1">
                            double_arrow
                        </span>
                    </div>
                </div> */}
      </div>
      <Modal
        show={showModal}
        onHide={handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <LoginModal handleClose={handleClose} />
      </Modal>
    </div>
  );
};

export default Hero;
