import React from 'react'
// import { HashLink as Link } from "react-router-hash-link";
import { NavLink as Link } from 'react-router-dom';
import '../../css/UserProfileDashboard/UserProfile.css';

const UserProfileNavbar = () => {
  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-light bg-light mt-3">
        {/* <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarTogglerUserProfile" aria-controls="navbarTogglerDemo02" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button> */}
        <div className="navbar-collapse">
          <ul className="navbar-nav mr-auto user-profile-nav">
            <li className="nav-item user-profile-nav-item">
              <Link className="nav-link" to="/profile-dashboard">
                <span className="material-symbols-outlined mx-1">
                  account_circle
                </span>
                Dashboard
              </Link>
            </li>
            <li className="nav-item user-profile-nav-item">
              <Link className="nav-link" to="planner">
                <span className="material-symbols-outlined mx-1">
                  grid_view
                </span>
                Planner
              </Link>
            </li>
            <li className="nav-item user-profile-nav-item">
              <Link className="nav-link" to="universities">
                <span className="material-symbols-outlined mx-1">
                  school
                </span>
                Universities
              </Link>
            </li>
            <li className="nav-item user-profile-nav-item">
              <Link className="nav-link" to="counselling">
                <span className="material-symbols-outlined mx-1">
                  account_circle
                </span>
                Counselling
              </Link>
            </li>
          </ul>
        </div>
      </nav>
    </>
  )
}

export default UserProfileNavbar
