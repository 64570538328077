import React, { useContext } from 'react';
import { UserContext } from '../UserProfileContext';
import { TextField } from '@material-ui/core';
import Chip from '@material-ui/core/Chip';
import CancelIcon from '@material-ui/icons/Cancel';

const DashboardSkill = () => {
  const { userDataFetched, setUserDataFetched, setDashboardStep } = useContext(UserContext);
  return (
    <div className='dashboard-skill-row w-100 mt-4'>
      <div className="col-md-7 my-2">
        <label className='py-2'><b>Skills</b></label><br />
        <TextField
          label="Skills"
          variant="outlined"
          className='form-input-field w-100'
          size='small'
          value={userDataFetched['aboutDetails']['skills'].join(', ')}
          onChange={(e) => {
            const skillsArray = e.target.value.split(',').map(skill => skill.trim());
            const updatedUserData = { ...userDataFetched };
            updatedUserData.aboutDetails.skills = skillsArray;
            setUserDataFetched(updatedUserData);
          }}
        />
        <div className='mt-2'>
          {userDataFetched.aboutDetails.skills.map((skill, index) => (
            <Chip
              key={index}
              label={skill}
              onDelete={() => {
                const updatedUserData = { ...userDataFetched };
                updatedUserData.aboutDetails.skills.splice(index, 1);
                setUserDataFetched(updatedUserData);
              }}
              deleteIcon={<CancelIcon />}
              className='my-2 mr-2'
            />
          ))}
        </div>
      </div>
      <div className="next-prev-btn">
        <div className='col-1'>
          <span className="material-symbols-outlined mt-4" onClick={() => { setDashboardStep(3) }}>
            keyboard_double_arrow_left
          </span>
        </div>
        <div className='col-1'>
          <span className="material-symbols-outlined mt-4" onClick={() => { setDashboardStep(5) }}>
            keyboard_double_arrow_right
          </span>
        </div>
      </div>
    </div>
  )
}

export default DashboardSkill
