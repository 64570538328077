import React, { useContext } from 'react';
import { UserContext } from '../UserProfileContext';
import { TextField, Select, MenuItem } from '@material-ui/core';

const DashboardJobExp = () => {
  const { userDataFetched, setUserDataFetched, setDashboardStep, showWorkExpForm, setWorkExpForm, jobNature, months, years } = useContext(UserContext);
  return (
    <div className='dashboard-jobexp-row w-100 mt-4'>
      <div className="form-check">
        <input
          className="form-check-input"
          type="radio" id="job-exp-radio"
          checked={showWorkExpForm}
          onChange={() => {
            setWorkExpForm(true)
          }}
        />
        <label className="form-check-label">
          I have work experience
        </label>
      </div>
      {showWorkExpForm ? <>
        <div className="row jobexp-form mt-3">
          <div className="col-md-6">
            <label className='py-2'><b>Job Position</b></label><br />
            <TextField
              placeholder='Ex: Software Engineer'
              variant="outlined"
              className='form-input-field w-100'
              size='small'
              value={userDataFetched['aboutDetails']['workExp']['jobPosition']}
              onChange={(e) => {
                const updatedUserData = { ...userDataFetched };
                updatedUserData.aboutDetails.workExp.jobPosition = e.target.value;
                setUserDataFetched(updatedUserData);
              }}
            />
          </div>
          <div className="col-md-6">
            <label className='py-2'><b>Organization</b></label><br />
            <TextField
              placeholder='Ex: Zyeero'
              variant="outlined"
              className='form-input-field w-100'
              size='small'
              value={userDataFetched['aboutDetails']['workExp']['orgName']}
              onChange={(e) => {
                const updatedUserData = { ...userDataFetched };
                updatedUserData.aboutDetails.workExp.orgName = e.target.value;
                setUserDataFetched(updatedUserData);
              }}
            />
          </div>
          <div className="col-md-12">
            <label className="mt-3">
              <b>Nature of your Job</b>
            </label>
            <div className="row job-nature-options">
              {jobNature.map((jobOption, index) => (
                <div
                  key={index}
                  className={`col-sm-2 job-nature-option mr-2 my-1 ${userDataFetched['aboutDetails']['workExp']['jobNature'] === jobOption ? 'selected-job-nature' : ''}`}
                  onClick={() => {
                    const updatedUserData = { ...userDataFetched };
                    updatedUserData.aboutDetails.workExp.jobNature = jobOption;
                    setUserDataFetched(updatedUserData);
                  }}>
                  <div className='my-2'>{jobOption}</div>
                </div>
              ))}
            </div>
          </div>
          <div className="col-md-12 my-4">
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                id="user-current-job"
                checked={userDataFetched['aboutDetails']['workExp']['currentJob']}
                onChange={(e) => {
                  const updatedUserData = { ...userDataFetched };
                  updatedUserData.aboutDetails.workExp.currentJob = e.target.checked;
                  setUserDataFetched(updatedUserData);
                }}
              />
              <label className="form-check-label">
                I currently work in this role
              </label>
            </div>
          </div>
          <div className="col-md-6 m-auto">
            <label className=''><b>Start Date</b></label>
            <div className="row">
              <div className="col-6">
                <Select
                  variant='outlined'
                  size='small'
                  className='col mr-2'
                  id="select"
                  value={userDataFetched['aboutDetails']['workExp']['startDate']['month']}
                  placeholder="Month"
                  onChange={(e) => {
                    const updatedUserData = { ...userDataFetched };
                    updatedUserData.aboutDetails.workExp.startDate.month = e.target.value;
                    setUserDataFetched(updatedUserData);
                  }}
                >
                  {
                    months.map((month, index) => (
                      <MenuItem
                        key={index}
                        value={month}
                      >
                        {month}
                      </MenuItem>
                    ))
                  }
                </Select>
              </div>
              <div className="col-6">
                <Select
                  variant='outlined'
                  size='small'
                  className='col'
                  id="select"
                  value={userDataFetched['aboutDetails']['workExp']['startDate']['year']}
                  placeholder="Month"
                  onChange={(e) => {
                    const updatedUserData = { ...userDataFetched };
                    updatedUserData.aboutDetails.workExp.startDate.year = e.target.value;
                    setUserDataFetched(updatedUserData);
                  }}
                >
                  {
                    years.map((year, index) => (
                      <MenuItem
                        key={index}
                        value={year}
                      >
                        {year}
                      </MenuItem>
                    ))
                  }
                </Select>
              </div>
            </div>
          </div>
          <div className="col-md-6 m-auto">
            <label className=''><b>End Date</b></label>
            <div className="row">
              <div className="col-6">
                <Select
                  variant='outlined'
                  size='small'
                  className='col mr-2'
                  id="select"
                  value={userDataFetched['aboutDetails']['workExp']['endDate']['month']}
                  placeholder="Month"
                  onChange={(e) => {
                    const updatedUserData = { ...userDataFetched };
                    updatedUserData.aboutDetails.workExp.endDate.month = e.target.value;
                    setUserDataFetched(updatedUserData);
                  }}
                >
                  {
                    months.map((month, index) => (
                      <MenuItem
                        key={index}
                        value={month}
                      >
                        {month}
                      </MenuItem>
                    ))
                  }
                </Select>
              </div>
              <div className="col-6">
                <Select
                  variant='outlined'
                  size='small'
                  className='col'
                  id="select"
                  value={userDataFetched['aboutDetails']['workExp']['endDate']['year']}
                  placeholder="Month"
                  onChange={(e) => {
                    const updatedUserData = { ...userDataFetched };
                    updatedUserData.aboutDetails.workExp.endDate.year = e.target.value;
                    setUserDataFetched(updatedUserData);
                  }}
                >
                  {
                    years.map((year, index) => (
                      <MenuItem
                        key={index}
                        value={year}
                      >
                        {year}
                      </MenuItem>
                    ))
                  }
                </Select>
              </div>
            </div>
          </div>
          <div className="col-md-12 my-3">
            <div className="form-group">
              <label className=''><b>Job Description</b><span className='text-muted'> (Optional)</span></label>
              <textarea
                className="form-control"
                placeholder='Describe type of your did'
                rows="3"
                value={userDataFetched['aboutDetails']['workExp']['jobDesc']}
                onChange={(e) => {
                  const updatedUserData = { ...userDataFetched };
                  updatedUserData.aboutDetails.workExp.jobDesc = e.target.value;
                  setUserDataFetched(updatedUserData);
                }}
              >
              </textarea>
            </div>
          </div>
        </div>
      </> : <></>}
      <div className="next-prev-btn">
        <div className='col-1'>
          <span className="material-symbols-outlined mt-4" onClick={() => { setDashboardStep(4) }}>
            keyboard_double_arrow_left
          </span>
        </div>
        <div className='col-1'>
          <span className="material-symbols-outlined mt-4" onClick={() => { setDashboardStep(6) }}>
            keyboard_double_arrow_right
          </span>
        </div>
      </div>
    </div>
  )
}

export default DashboardJobExp
