import React from 'react'
import ResetPasswordC1 from '../components/ResetPassword/ResetPasswordC1'

const ResetPassword = () => {
  return (
    <div>
      <ResetPasswordC1/>
    </div>
  )
}

export default ResetPassword
