import React from 'react'

const Counsellor = () => {
  return (
    <div>
      Counsellor
    </div>
  )
}

export default Counsellor
