import React, { useContext, useEffect } from 'react'
import ErrorPage from './ErrorPage'
import axios from 'axios';
import UserProfile from '../components/UserProfileDashboard/UserProfile';
import { UserContext } from '../components/UserProfileDashboard/UserProfileContext';
import animationData from '../lotties/reload-lottie2.json';
import Lottie from 'react-lottie-player';
import UserProfileNavbar from '../components/UserProfileDashboard/UserProfileNavbar';
import { Outlet } from 'react-router-dom';
import dayjs from 'dayjs';

const UserProfileDashboard = () => {
  document.title = 'User Dashboard'
  // const user_context = useContext(UserContext);
  // console.log(user_context);
  const { userDataFetched, setUserDataFetched, setUtilityData, responseStatus, setResponseStatus, loading, setLoading, setEnglishStep, setAptitudeStep, setSelectedGradYear, setSelectedPrefCountry, setWorkExpForm, setResearchDone } = useContext(UserContext);
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);

        const token = localStorage.getItem('token');
        const headers = {
          'Authorization': 'Bearer ' + token
        };

        const response = await axios.get("https://zyeero-server.onrender.com/api/userActions/getUser", { headers });
        const util_response = await axios.get("https://zyeero-server.onrender.com/api/utilities")
        setUserDataFetched(response.data);
        setResponseStatus(response.status);;
        localStorage.setItem('fixedUserData', JSON.stringify(response.data));
        setUtilityData(util_response.data);

        const date = dayjs((response.data.aboutDetails.collegeDetails.gradYear).toString());
        setSelectedGradYear(date);

        const prefCountry = (response.data.preferredCountry).map((option)=>({
          'label': option.countryName,
          'value': option.countryName,
          'id': option.countryId
        }));
        setSelectedPrefCountry(prefCountry);

        console.log(response.data.preferredCountry)
        
        if (response.data.aboutDetails.englishTestDetails.notTaken) {
          setEnglishStep(4);
        }
        if (response.data.aboutDetails.aptitudeTestDetails.notTaken) {
          setAptitudeStep(3);
        }
        if (response.data.aboutDetails.workExp.jobPosition === '') {
          setWorkExpForm(false);
        }
        if (response.data.aboutDetails.researchDetails.paperTitle === '') {
          setResearchDone(false);
        }
        // console.log(response.data);
      } catch (error) {
        console.log(error.response);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [])

  if (loading) {
    return (
      <div className="text-danger">
        <Lottie
          rendererSettings={{ preserveAspectRatio: 'xMidYMid slice' }}
          loop
          animationData={animationData}
          play
          style={{ height: '70vh', width: '100%' }}
        />
      </div>
    )
  }
  else if (responseStatus === 200) {
    return (
      <div className="user-dashboard-row row">
        <UserProfile />
        <div className="col-md">
          <UserProfileNavbar />
          <Outlet />
        </div>
      </div>
    )
  } else {
    return (
      <ErrorPage />
    )
  }
}

export default UserProfileDashboard
