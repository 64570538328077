import React from 'react'
import Hero from '../components/Home/Hero'
import FeatureTabs from '../components/Home/FeatureTabs'
import Home_C2 from '../components/Home/Home_C2'
import Home_C3 from '../components/Home/Home_C3'
import Home_C4 from '../components/Home/Home_C4'
import Home_C5 from '../components/Home/Home_C5'
import Home_C6 from '../components/Home/Home_C6'
import Home_C7 from '../components/Home/Home_C7'
import Home_C8 from '../components/Home/Home_C8'
import Home_C9 from '../components/Home/Home_C9'
import Home_C10 from '../components/Home/Home_C10'
import FeatureItemLists from '../components/Home/FeatureItems'
import Home_C11 from '../components/Home/Home_C11'
import UniversitiesList from '../components/Home/UniversitiesList'
import Blog from '../components/Home/Blog'
import Metrics from '../components/Home/Metrics'

const Home = () => {
  document.title = 'Zyeero'
  return (
    <div>
      <Hero/>
      <FeatureItemLists/>
      <FeatureTabs/>
      <UniversitiesList/>
      <Blog />
      <Metrics />
      {/* <Home_C3/>
      <Home_C4/>
      <Home_C5/>
      <Home_C6/>
      <Home_C7/>
      <Home_C8/>
      <Home_C9/>
      <Home_C10/>
      <Home_C11/> */}
      {/* <Home_C2/> */}
    </div>
  )
}

export default Home
