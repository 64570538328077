import React, { useContext } from 'react';
import { UserContext } from '../UserProfileContext';
import { TextField } from '@material-ui/core';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import '../../../css/UserProfileDashboard/UserProfile.css';
import Chip from '@material-ui/core/Chip';
import CancelIcon from '@material-ui/icons/Cancel';
import Autocomplete from '@mui/material/Autocomplete';

const DashboardUnderGrad = () => {
  const { userDataFetched, setUserDataFetched, setDashboardStep, selectedGradYear, setSelectedGradYear, selectedPrefCountry, setSelectedPrefCountry, prefCountries } = useContext(UserContext);
  return (
    <div className='undergrad-row'>
      <b className='mt-4'>All About your Undergrad</b>
      <div className="text-muted">Get faster and more accurate College Finder results by adding your UG details.</div>
      <div className="mt-5 row undergrad-forms">
        <div className="col-md-6 my-2">
          <label className='py-2'><b>College name</b></label><br />
          <TextField
            variant="outlined"
            className='form-input-field w-100'
            size='small'
            value={userDataFetched['aboutDetails']['collegeDetails']['collegeName']}
            onChange={(e) => {
              const updatedUserData = { ...userDataFetched };
              updatedUserData.aboutDetails.collegeDetails.collegeName = e.target.value;
              setUserDataFetched(updatedUserData);
            }}
          />
        </div>
        <div className="col-md-6 my-2">
          <label className='py-2'><b>Course name</b></label><br />
          <TextField
            variant="outlined"
            className='form-input-field w-100'
            size='small'
            value={userDataFetched['aboutDetails']['collegeDetails']['courseName']}
            onChange={(e) => {
              const updatedUserData = { ...userDataFetched };
              updatedUserData.aboutDetails.collegeDetails.courseName = e.target.value;
              setUserDataFetched(updatedUserData);
            }}
          />
        </div>
        <div className="col-md-6 my-2">
          <label className='py-2'><b>Score</b></label>
          <div className='select-score'>
            <Select
              value={userDataFetched['aboutDetails']['collegeDetails']['gradScore']['scoreType']}
              onChange={(e) => {
                const updatedUserData = { ...userDataFetched };
                updatedUserData.aboutDetails.collegeDetails.gradScore.scoreType = e.target.value;
                updatedUserData.aboutDetails.collegeDetails.gradScore.score = 0;
                setUserDataFetched(updatedUserData);
              }}
            >
              <MenuItem value="4-GPA">4 GPA</MenuItem>
              <MenuItem value="10-GPA">10 GPA</MenuItem>
              <MenuItem value="%">%</MenuItem>
            </Select>

            <TextField
              // size='medium'
              placeholder='Add Total CGPA/CGPA/%'
              type="number"
              variant="outlined"
              className='form-input-field w-100'
              value={userDataFetched['aboutDetails']['collegeDetails']['gradScore']['score']}
              onChange={(e) => {
                const updatedUserData = { ...userDataFetched };
                updatedUserData.aboutDetails.collegeDetails.gradScore.score = parseInt(e.target.value, 10);
                setUserDataFetched(updatedUserData);
              }}
              inputProps={
                { readOnly: userDataFetched.aboutDetails.collegeDetails.gradScore.scoreType === '' }
              }
            />
          </div>
        </div>
        <div className="col-md-6 my-2">
          <label className='py-2'><b>Graduation Year(Optional)</b></label>
          {/* <TextField
            // label={`Score (${gradingSystem})`}
            type="number"
            variant="outlined"
            className='form-input-field w-100'
            value={userDataFetched['aboutDetails']['collegeDetails']['gradYear']}
            onChange={(e) => {
              const updatedUserData = { ...userDataFetched };
              updatedUserData.aboutDetails.collegeDetails.gradYear = e.target.value;
              setUserDataFetched(updatedUserData);
            }}
            InputProps={{
              inputProps: {
                min: 1900, // You can adjust the minimum year as needed
                max: new Date().getFullYear(), // You can adjust the maximum year as needed
                placeholder: 'YYYY',
              },
            }}
          /> */}
          <div className='grad-year-select'>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                views={['year']}
                variant="outlined"
                // label="Year"
                inputFormat="yyyy"
                value={selectedGradYear}
                onChange={(newGradYear) => {
                  const updatedUserData = { ...userDataFetched };
                  updatedUserData.aboutDetails.collegeDetails.gradYear = newGradYear.$y;
                  setUserDataFetched(updatedUserData);
                  setSelectedGradYear(newGradYear);
                }}
                className='w-100'
                renderInput={(params) => <TextField {...params} variant="outlined"
                  className='form-input-field' />}
              />
            </LocalizationProvider>
          </div>
        </div>
        <div className="col-md-7 my-2">
          <label className='py-2'><b>Backlogs</b></label>
          <TextField
            placeholder='eg: 0'
            type="number"
            size='small'
            variant="outlined"
            className='form-input-field w-100'
            value={userDataFetched['aboutDetails']['collegeDetails']['backlogs']}
            onChange={(e) => {
              const updatedUserData = { ...userDataFetched };
              updatedUserData.aboutDetails.collegeDetails.backlogs = parseInt(e.target.value, 10);
              setUserDataFetched(updatedUserData);
              console.log((localStorage.getItem('fixedUserData')));
              console.log(JSON.stringify(userDataFetched))
            }}
          />
        </div>
        <div className="col-md-7 my-2">
          <label className='py-2'><b>Course Preferences</b></label><br />
          <TextField
            variant="outlined"
            className='form-input-field w-100'
            size='small'
            value={userDataFetched['preferredMajors'].join(', ')}
            onChange={(e) => {
              const majorArray = e.target.value.split(',').map(major => major.trim());
              const updatedUserData = { ...userDataFetched };
              updatedUserData.preferredMajors = majorArray;
              setUserDataFetched(updatedUserData);
            }}
          />
          <div className='mt-2'>
            {userDataFetched.preferredMajors.map((major, index) => (
              <Chip
                key={index}
                label={major}
                onDelete={() => {
                  const updatedUserData = { ...userDataFetched };
                  updatedUserData.preferredMajors.splice(index, 1);
                  setUserDataFetched(updatedUserData);
                }}
                deleteIcon={<CancelIcon />}
                className='my-2 mr-2'
              />
            ))}
          </div>
        </div>
        <div className="col-md-7">
          <label className='mt-3'><b>Your Preferred Countries</b></label>
          <Autocomplete
            multiple
            className='form-input-field mt-2'
            size="small"
            options={prefCountries}
            getOptionLabel={(option) => option.label}
            value={selectedPrefCountry}
            limitTags={2}
            onChange={(e, newValue) => {
              console.log(newValue)
              const updatedUserData = { ...userDataFetched };
              updatedUserData['preferredCountry'] = newValue.map(option => ({
                'countryName': option.value,
                'countryId': option.id
              }));
              setUserDataFetched(updatedUserData);
              setSelectedPrefCountry(newValue);
            }
            }
            renderInput={(params) => (
              <TextField {...params} variant="outlined" fullWidth />
            )}
          />
        </div>
      </div>
      <div className="next-prev-btn">
        <div className='col-1'>
          <span className="material-symbols-outlined mt-4 text-muted">
            keyboard_double_arrow_left
          </span>
        </div>
        <div className='col-1'>
          <span className="material-symbols-outlined mt-4" onClick={() => { setDashboardStep(2) }}>
            keyboard_double_arrow_right
          </span>
        </div>
      </div>
    </div>
  )
}

export default DashboardUnderGrad
