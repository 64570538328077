import React from 'react';
import { Tilt } from 'react-tilt'
import "./TiltCard.css"

function TiltCard(props) {
  return (
    <Tilt className="Tilt" options={{ max : 15, scale: 1.05 }}>
      <h1 className='card-title'>{props.cardTitle}</h1>
      <img src={require("../../assets/uni.png")} className='Tilt-Card-Image'></img>
    </Tilt>
  );
}

export default TiltCard;
