import React, { useState } from 'react';
import App from '../../App';
import { Country, State } from 'country-state-city';
import dayjs from 'dayjs';
import axios from 'axios';
import Swal from 'sweetalert2';
import Signup from '../../pages/Signup.js'
export const multiStepContext = React.createContext();

const SignupStepContext = () => {
  //State to maintain the Form step number 
  const [currentStep, setStep] = useState(1);

  //State to collect User Data and initializaing major_options, pref_countries field with empty array
  const [userData, setUserData] = useState({
    major_options: [],
    pref_countries: []
  });

  //List of Countries
  const countryCodes = ['IN', 'US'];
  var updatedCountries = countryCodes.map((country) => {
    const countryInfo = Country.getCountryByCode(country);
    return { label: countryInfo.name, value: countryInfo.name, id: countryInfo.isoCode };
  });

  //List of States for a selected Country
  var updatedStates = (countryId) => ((State.getStatesOfCountry(countryId)).map((state) => ({ label: state.name, value: state.name, id: state.isoCode })))

  //State to collect Confirm Password value
  const [confirmPassword, setConfirmPassword] = useState('');

  //List of Major Options
  const majorOptions = [
    { label: 'Computer Science', value: 'Computer Science' },
    { label: 'Business Analytics', value: 'Business Analytics' },
    { label: 'Data Science', value: 'Data Science' },
    { label: 'Management Information Systems', value: 'Management Information Systems' },
    { label: 'Robotics', value: 'Robotics' },
  ];

  //State to collect the input value for Major Options
  const [inputValue, setInputValue] = useState('');

  //Handler to set the inputValue field when user selects one Major Option
  const handleInputChange = (event, newInputValue) => {
    setInputValue(newInputValue);
  };

  //Function to filter Major Options - Used to remove the option from the list which is already selected by the user
  const filterOptions = (options, { inputValue }) => {
    return options.filter(
      (option) => !userData.major_options.some((selectedOption) => selectedOption.label === option.label)
    );
  };

  //Disabling the Major Options if the number of selected options is 3
  const getOptionDisabled = (option) => {
    return userData.major_options.length >= 3 && !userData.major_options.includes(option);
  };

  //Array of Degree Options - Bachelors and Masters
  const degreeOptions = [{
    degree: 'Bachelors',
    degree_img: 'bachelor.jpg'
  },
  {
    degree: 'Masters',
    degree_img: 'master.jpg'
  }
  ]

  //Array of Terms
  const terms = [{
    season: 'Fall',
    season_start: 'Aug',
    season_end: 'Dec',
    season_img: 'leaves.png'
  },
  {
    season: 'Spring',
    season_start: 'Jan',
    season_end: 'May',
    season_img: 'rainbow.png'
  },
  {
    season: 'Summer',
    season_start: 'May',
    season_end: 'July',
    season_img: 'sun.png'
  }]

  //Preferred Countries
  const prefCountries = [{
    label: 'USA',
    value: 'USA',
    id: 'US'
  },
  {
    label: 'UK',
    value: 'UK',
    id: 'UK'
  },
  {
    label: 'Australia',
    value: 'Australia',
    id: 'AU'
  },
  {
    label: 'Germany',
    value: 'Germany',
    id: 'DE'
  },
  {
    label: 'Canada',
    value: 'Canada',
    id: 'CA'
  },
  {
    label: 'France',
    value: 'France',
    id: 'FR'
  },
  {
    label: 'Ireland',
    value: 'Ireland',
    id: 'IE'
  },
  {
    label: 'Netherlands',
    value: 'Netherlands',
    id: 'NL'
  },
  {
    label: 'Switzerland',
    value: 'Switzerland',
    id: 'CH'
  },
  {
    label: 'Singapore',
    value: 'Singapore',
    id: 'SG'
  }]


  //State to handle Terms and Conditions checkbox
  const [checked, setChecked] = useState(false);

  //Submit Sign Up Form
  const handleSignUp = () => {
    const swalLoad = Swal.fire({
      title: 'Sending Verification Mail',
      html: 'Please wait...',
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading()
      }
    });
    
    const formattedDate = dayjs(userData['dob'].$d).format('DD-MM-YYYY');
  
    const data = {
      "email": userData['email'],
      "password": userData['password'],
      "firstName": userData['firstname'],
      "lastName": userData['lastname'],
      "username": userData['username'],
      "dob": formattedDate,
      "gender": userData['gender'],
      "phone": userData['phonenum'],
      "city": "Kolkata",
      "state": userData['state']['state_name'],
      "country": {
        "countryId": userData['country']['country_id'],
        "countryName": userData['country']['country_name']
      },
      "userSource": userData['hear_about_us'],
      "chosenDegree": userData['degree'],
      "chosenService": "gre",
      "preferredMajors": userData['major_options'].map(option => option.value),
      "termOfStudy": userData['term'],
      "preferredCountry": userData['pref_countries'].map(country => ({
        "countryId": country.id,
        "countryName": country.value

      }))
    }
    console.log(data)
    axios.post("https://zyeero-server.onrender.com/api/initUser/register", data)
      .then(response => {
        swalLoad.close();
        console.log(response);
        Swal.fire({
          title: 'SUCCESS',
          text: response.data.message,
          icon: 'success',
          confirmButtonText: 'OK',
        }).then(() => {
          window.location.href = "/"
        });
      })
      .catch(error => {
        console.log(error.response.data);
        Swal.fire({
          title: 'SIGNUP FAILED',
          text: error.response.data.message,
          icon: 'error',
          confirmButtonText: 'TRY AGAIN',
        })
      });
  }

  return (
    <div>
      <multiStepContext.Provider value={{ currentStep, setStep, userData, setUserData, updatedCountries, updatedStates, confirmPassword, setConfirmPassword, majorOptions, inputValue, handleInputChange, filterOptions, checked, getOptionDisabled, degreeOptions, terms, prefCountries, setChecked, handleSignUp }}>
        <Signup />
      </multiStepContext.Provider>
    </div>
  )
}

export default SignupStepContext
