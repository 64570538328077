import React from "react";

import "../../css/Home/Blog.css";
import CarouselCard from "../UIComponents/CourselCard/CarouselCard";
import BlogCard from "../UIComponents/BlogCard/BlogCard";

function Blog() {
  return (
    <div className="blog-section">
      <div className="blog-section-div">
        <div className="blog-section-header">
          <h1 className="blog-section-header-1 item1">
            Don't miss out our recent articles
          </h1>
          <h5 className="blog-section-header-2 item2">
            We share common trends
          </h5>
        </div>
        <div className="blog-carousel-section">
          <BlogCard
            author={"Jane Doe"}
            title={"How to Apply to a University in Italy ?"}
            description={
              "Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled."
            }
            imageSource={
              "https://images.unsplash.com/photo-1671726203454-5d7a5370a9f4?q=80&w=2670&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3Dhttps://images.unsplash.com/photo-1671726203454-5d7a5370a9f4?q=80&w=2670&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
            }
          />
          <BlogCard
            author={"Jane Doe"}
            title={"How to Apply to a University in Italy ?"}
            description={
              "Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled."
            }
            imageSource={
              "https://images.unsplash.com/photo-1517256673644-36ad11246d21?q=80&w=2574&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D0"
            }
          />
          <BlogCard
            author={"Jane Doe"}
            title={"How to Apply to a University in Italy ?"}
            description={
              "Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled."
            }
            imageSource={
              "https://images.unsplash.com/photo-1521737711867-e3b97375f902?q=80&w=2574&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
            }
          />
        </div>
      </div>
    </div>
  );
}

export default Blog;
