import React, { useContext } from 'react';
import { UserContext } from '../UserProfileContext';
import DashboardEngTOEFL from './DashboardEngTOEFL';
import DashboardEngIELTS from './DashboardEngIELTS';
import DashboarEngPTE from './DashboarEngPTE';

const DashboardEnglish = () => {
  const { setDashboardStep, currentEnglishStep, setEnglishStep, userDataFetched, setUserDataFetched } = useContext(UserContext);
  function showDashboardEnglishStep(step) {
    switch (step) {
      case 1:
        return <DashboardEngTOEFL />
      case 2:
        return <DashboardEngIELTS />
      case 3:
        return <DashboarEngPTE />
      case 4:
        return <></>
    }
  }
  return (
    <div className='dashboard-english-row w-100 mt-4'>
      <div className="row">
        <b>English Test taken</b>
      </div>
      <div className="english-test-options-row mt-3">
        <div className={`my-2 mr-2 btn btn-english-test ${currentEnglishStep === 1 ? 'english-active' : ''}`} onClick={() => { setEnglishStep(1) }}>TOEFL</div>
        <div className={`my-2 mx-2 btn btn-english-test ${currentEnglishStep === 2 ? 'english-active' : ''}`} onClick={() => { setEnglishStep(2) }}>IELTS</div>
        <div className={`my-2 mx-2 btn btn-english-test ${currentEnglishStep === 3 ? 'english-active' : ''}`} onClick={() => { setEnglishStep(3) }}>PTE</div>
        <div
        
          className={`my-2 mx-2 btn btn-english-test ${currentEnglishStep === 4 ? 'english-active' : ''}`}
          onClick={() => {
            setEnglishStep(4); 
            const updatedUserData = { ...userDataFetched };
            updatedUserData.aboutDetails.englishTestDetails.notTaken = true;
            setUserDataFetched(updatedUserData);
          }}>
          Not Taken
        </div>
      </div>
      {showDashboardEnglishStep(currentEnglishStep)}
      <div className="next-prev-btn">
        <div className='col-1'>
          <span className="material-symbols-outlined mt-4" onClick={() => { setDashboardStep(1) }}>
            keyboard_double_arrow_left
          </span>
        </div>
        <div className='col-1'>
          <span className="material-symbols-outlined mt-4" onClick={() => { setDashboardStep(3) }}>
            keyboard_double_arrow_right
          </span>
        </div>
      </div>
    </div>
  )
}

export default DashboardEnglish
