import React, { useContext } from 'react';
import { UserContext } from '../UserProfileContext';

const DashboardResearch = () => {
    const { userDataFetched, setUserDataFetched, setDashboardStep, publicationLevels, isResearchDone, setResearchDone } = useContext(UserContext);
    return (
        <div className='dashboard-research-row w-100 mt-4'>
            <div className="row">
                <b>Your hand in Research</b>
                <div className="text-muted">Let your fellow aspirants know that relevant research can help getting an admit.</div>
            </div>
            <div className="form-check mt-3">
                <input
                    className="form-check-input"
                    type="radio" id="job-exp-radio"
                    checked={!isResearchDone}
                    onChange={() => {
                        setResearchDone(false);
                        const updatedUserData = { ...userDataFetched };
                        updatedUserData.aboutDetails.researchDetails.description = '';
                        updatedUserData.aboutDetails.researchDetails.paperTitle = '';
                        updatedUserData.aboutDetails.researchDetails.pubclicationLevel = '';
                        setUserDataFetched(updatedUserData);
                    }}
                />
                <label className="form-check-label">
                    I have not done any research
                </label>
            </div>
            <div className="form-check">
                <input
                    className="form-check-input"
                    type="radio" id="job-exp-radio"
                    checked={isResearchDone}
                    onChange={() => {
                        setResearchDone(true)
                    }}
                />
                <label className="form-check-label">
                    I have done some research
                </label>
            </div>
            {isResearchDone ? <>
                <div className="row mt-4 research-form">
                    <div className="col-md-12">
                        <label className='py-2'><b>Paper Title</b></label><br />
                        <textarea
                            className="form-control"
                            placeholder='Type a Title'
                            rows="1"
                            maxlength="250"
                            value={userDataFetched['aboutDetails']['researchDetails']['paperTitle']}
                            onChange={(e) => {
                                const updatedUserData = { ...userDataFetched };
                                updatedUserData.aboutDetails.researchDetails.paperTitle = e.target.value;
                                setUserDataFetched(updatedUserData);
                            }}
                        >
                        </textarea>
                        <small className='form-text text-muted eng-helper-text'>
                            <span className="material-symbols-outlined mr-1">
                                info
                            </span> No more than 250 characters
                        </small>
                    </div>
                    <div className="col-md-12">
                        <label className="mt-3">
                            <b>Nature of your Job</b>
                        </label>
                        <div className="row job-nature-options">
                            {publicationLevels.map((publicationLevel, index) => (
                                <div
                                    key={index}
                                    className={`col-sm-2 job-nature-option mr-2 my-1 ${userDataFetched['aboutDetails']['researchDetails']['pubclicationLevel'] === publicationLevel ? 'selected-job-nature' : ''}`}
                                    onClick={() => {
                                        const updatedUserData = { ...userDataFetched };
                                        updatedUserData.aboutDetails.researchDetails.pubclicationLevel = publicationLevel;
                                        setUserDataFetched(updatedUserData);
                                    }}>
                                    <div className='my-2'>{publicationLevel}</div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="col-md-12">
                        <label className='py-2'><b>Description</b><span className="text-muted"> (Optional)</span></label><br />
                        <textarea
                            className="form-control"
                            placeholder='Describe type of Research you did'
                            rows="3"
                            maxlength="250"
                            value={userDataFetched['aboutDetails']['researchDetails']['description']}
                            onChange={(e) => {
                                const updatedUserData = { ...userDataFetched };
                                updatedUserData.aboutDetails.researchDetails.description = e.target.value;
                                setUserDataFetched(updatedUserData);
                            }}
                        >
                        </textarea>
                    </div>
                </div>
            </> : <></>}
            <div className="next-prev-btn">
                <div className='col-1'>
                    <span className="material-symbols-outlined mt-4" onClick={() => { setDashboardStep(5) }}>
                        keyboard_double_arrow_left
                    </span>
                </div>
                <div className='col-1'>
                    <span className="material-symbols-outlined mt-4 text-muted">
                        keyboard_double_arrow_right
                    </span>
                </div>
            </div>
        </div>
    )
}

export default DashboardResearch
