import React, { useContext } from 'react'
import '../../css/UserProfileDashboard/UserProfile.css';
import { UserContext } from './UserProfileContext.js';
const UserProfile = () => {
    const { userDataFetched, setUserDataFetched, responseStatus, setResponseStatus, loading, setLoading, handleLogout } = useContext(UserContext);
    return (
        <div className='col-md-4 user-profile-col-4'>
            <img src={require("../../assets/user_avatar.png")} className="user-profile-pic" alt="" />
            <div className="text-muted mt-3 user-username">
                {userDataFetched.username}
            </div>
            <div className="user-degree-intake mt-5">
                <div className="col-md user-degree">
                    <div className="user-degree-header"><b>DEGREE</b></div>
                    <div className='degree-name'><img className="mx-2 my-2 user-degree-icon" src={require("../../assets/user-degree.png")} />{userDataFetched.chosenDegree}</div>
                </div>
                <div className="col-md user-intake">
                    <div className="user-degree-header"><b>INTAKE</b><span class="material-symbols-outlined mx-2 edit-pencil">
                        border_color
                    </span></div>
                    <div className='degree-name'><img className="mx-2 my-2 user-degree-icon" src={require("../../assets/leaves.png")} />{userDataFetched.termOfStudy} 2024</div>
                </div>
            </div>
            <div className="loan-finder mt-3">
                <img className="loan-img" src={require('../../assets/loan.png')} alt="" />
                <h4 className='text-white text-center pt-3'>Want Financial Support?</h4>
                <p className='text-white text-center'>We are here to finance your study abroad dreams!</p>
                <button className="btn loan-finder-button mt-4">Try Out Loan Finder</button>
                <small className='text-white mt-1'>No charge. It's on us!</small>
            </div>
            <button className="btn btn-primary mt-4" onClick={handleLogout}>
                Logout
            </button>
        </div>
    )
}

export default UserProfile
