import React from "react";

import "../../css/Home/UniversitiesList.css";
import CarouselCard from "../UIComponents/CourselCard/CarouselCard";

function Universities() {
  return (
    <div className="universities-section">
      <div className="universities-section-div">
        <div>
          <h1 className="universities-section-header-1">
            Tied up with Universities
          </h1>
          <h5 className="universities-section-header-2">
            across various countries
          </h5>
          <a>SEE ALL-</a>
        </div>
        <div className="universities-carousel-section">
          <CarouselCard cardTitle={"Study in Australia"} imageSouce={"https://images.unsplash.com/photo-1549923015-22d03fbac451?q=80&w=2670&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"} />
          <CarouselCard cardTitle={"Study in USA"} imageSouce={"https://images.unsplash.com/photo-1515081774057-84dcf72d0cf1?q=80&w=2630&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"} />
          <CarouselCard cardTitle={"Study in England"} imageSouce={"https://images.unsplash.com/photo-1545073334-9cb53498f1dc?q=80&w=2574&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"} />
          <CarouselCard cardTitle={"Study in Canada"} imageSouce={"https://images.unsplash.com/photo-1586576782138-19304c43d0e1?q=80&w=2574&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"} />
        </div>
      </div>
    </div>
  );
}

export default Universities;
