import React, { useContext } from 'react';
import { TextField } from '@material-ui/core';
import '../../css/Signup/SignUpForm.css';
import { multiStepContext } from './SignupStepContext';
import Autocomplete from '@mui/material/Autocomplete';

const SignupForm5 = () => {
  const { setStep, userData, setUserData, terms, prefCountries } = useContext(multiStepContext);
  return (
    <div className='singunp-step-form'>
      <div className="row form-field-row mx-3">

        {/* Term */}
        <label className="mt-3"><b>Term you plan to start studying</b></label>
        <div className="row term-planning-row">
          {terms.map((term, index) => (
            <div
              key={index}
              className={`term-planning-option ${userData['term'] === term.season ? 'selected-term' : ''}`}
              onClick={() => {
                setUserData({ ...userData, "term": term.season })
                document.getElementById('term-checkbox').checked = false
              }}
            >
              <div>
                <img src={require(`../../assets/${term.season_img}`)} className="season-image" alt="" />
              </div>
              <div className="col my-2">
                <div className="term-period">{term.season_start}-{term.season_end}</div>
                <div className="term-name">{term.season}</div>
              </div>
            </div>
          ))}

          {/* Term - Not Decided checkbox */}
          <div className="form-check mt-1">
            <input className="form-check-input" type="checkbox" value="" id="term-checkbox" onChange={(e) => setUserData({ ...userData, "term": (e.target.checked ? '' : null) })} />
            <label className="form-check-label" for="flexCheckDefault">
              Not decided yet
            </label>
          </div>

        </div>

        {/* Preferred Country */}
        <label className='mt-3'><b>Your Preferred Countries</b></label>
        <Autocomplete
          multiple
          className='form-input-field mt-2'
          size="small"
          options={prefCountries}
          getOptionLabel={(option) => option.label}
          value={userData['pref_countries']}
          // inputValue={inputValue}
          limitTags={1}
          // onInputChange={handleInputChange}
          // filterOptions={filterOptions}
          // getOptionDisabled={getOptionDisabled}
          onChange={(e, pref_countries) => { setUserData({ ...userData, "pref_countries": pref_countries }) }}
          renderInput={(params) => (
            <TextField {...params} label="Select Preferred Countries" variant="outlined" fullWidth />
          )}
        />
        {/* <small className="text-muted">Choose upto 3 Majors</small> */}
        <div className="row signup-form-btn-row my-4">
          <button type='button' className='btn btn-primary col-3 mx-2' onClick={() => { setStep(4) }}>Back</button>
          <button type='button' className='btn btn-success col-3' onClick={() => { setStep(6) }} disabled={((userData['pref_countries']).length === 0) || (userData['term']===null)}>Next</button>
        </div>
      </div>
    </div>
  )
}

export default SignupForm5
