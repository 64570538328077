import React from "react";
import TiltCard from "../UIComponents/TiltCard";

import "../../css/Home/FeatureItems.css";

const FeatureItemLists = () => {
  return (
    <div className="feature-items-section">
      <h4 className="feature-item-header-2 feature-item-header">Our tools</h4>
      <h1 className="feature-item-header-1 feature-item-header">
        Tech for studying abroad
      </h1>
      <div className="feature-list-section">
        <TiltCard cardTitle="Exploring Universities" />
        <TiltCard cardTitle="Loan Assistance" />
        <TiltCard cardTitle="Visa Help" />
        <TiltCard cardTitle="Exam Prep" />
        <TiltCard cardTitle="Insurance" />
        <TiltCard cardTitle="Moving Abroad" />
      </div>
    </div>
  );
};

export default FeatureItemLists;
