import React, { useState, useContext } from 'react';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import '../../css/Signup/SignUpForm.css';
import { multiStepContext } from './SignupStepContext';
import Select from "react-select";


const SignupForm2 = () => {
  const { setStep, userData, setUserData, updatedCountries, updatedStates } = useContext(multiStepContext);
  return (
    <div className='singunp-step-form'>
      <div className="row form-field-row mx-3">

        {/* Date of Birth */}
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <label className='mt-5' required><b>Date of Birth</b></label>
          <DemoContainer components={['DatePicker']}>
            <DatePicker
              size="small"
              label=""
              value={userData['dob']}
              // value={userData['dob'] ? dayjs(userData['dob']) : null}// Ensure userData['dob'] is a valid date string
              // inputFormat="DD-MM-YYYY"
              // format="DD-MM-YYYY"
              onChange={(newDate) => {
                // const formattedDate = newDate.format('DD-MM-YYYY');
                // setUserData({ ...userData, "dob": formattedDate });
                setUserData({ ...userData, "dob": newDate });
              }}
              required />
          </DemoContainer>
        </LocalizationProvider>

        {/* Gender */}
        <label className='mt-3'><b>Gender</b></label>
        <RadioGroup
          row
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
          value={userData['gender']}
          onChange={(e) => setUserData({ ...userData, "gender": e.target.value })}
        >
          <FormControlLabel value="Female" control={<Radio />} label="Female" />
          <FormControlLabel value="Male" control={<Radio />} label="Male" />
          <FormControlLabel value="Other" control={<Radio />} label="Other" />
        </RadioGroup>

        {/* Country */}
        <label className='mt-1'><b>Your Country</b></label>
        <Select
          options={updatedCountries}
          value={userData['country'] ? { label: userData['country']['country_name'], id: userData['country']['country_id'], value: userData['country']['country_name'] } : null}
          onChange={(countryValue) => setUserData({ ...userData, "country": { "country_id": countryValue.id, "country_name": countryValue.value } })}
        />
        <label className='mt-1'><b>Your State</b></label>

        {/* State */}
        <Select
          options={updatedStates(userData['country'] ? userData['country']['country_id'] : null)}
          value={userData['state'] ? { label: userData['state']['state_name'], id: userData['state']['state_id'], value: userData['state']['state_name'] } : null}
          onChange={(stateValue) => setUserData({ ...userData, "state": { "state_id": stateValue.id, "state_name": stateValue.value } })}
        />

        {/* Next and Back button rows */}
        <div className="row signup-form-btn-row my-4">
          {/* Back Button */}
          <button type='button' className='btn btn-primary col-3 mx-2' onClick={() => { setStep(1) }}>Back</button>
          {/* Next Button */}
          <button type='button' className='btn btn-success col-3' onClick={() => { setStep(3) }} disabled={(!userData['dob']) || (!userData['gender']) || (!userData['country']) || (!userData['state'])}>Next</button>
        </div>
      </div>
    </div>
  )
}

export default SignupForm2
