import React, { useContext } from 'react'
import _isEqual from 'lodash/isEqual';
import DashboardUnderGrad from './DashboardUnderGrad';
import DashboardEnglish from './DashboardEnglish';
import DashboardAptitude from './DashboardAptitude';
import DashboardSkill from './DashboardSkill';
import DashboardJobExp from './DashboardJobExp';
import DashboardResearch from './DashboardResearch';
import { UserContext } from '../UserProfileContext';

const DashboardForm = () => {
  const { userDataFetched, currentDashboardStep, saveUserData } = useContext(UserContext);
  function showDashboardFormStep(step) {
    switch (step) {
      case 1:
        return <DashboardUnderGrad />
      case 2:
        return <DashboardEnglish />
      case 3:
        return <DashboardAptitude />
      case 4:
        return <DashboardSkill />
      case 5:
        return <DashboardJobExp />
      case 6:
        return <DashboardResearch />
    }
  }
  return (
    <div className='col d-flex flex-column justify-content-center align-items-center'>
      {showDashboardFormStep(currentDashboardStep)}
      <div className="px-3 w-100">
        <button className="btn btn-success w-100" onClick={saveUserData} disabled={_isEqual(JSON.parse(localStorage.getItem('fixedUserData')), userDataFetched)}>Save</button>
      </div>
    </div>
  )
}

export default DashboardForm
