import React, { useContext } from 'react';
import { UserContext } from '../UserProfileContext';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import FormHelperText from '@mui/material/FormHelperText';

const DashboardAptGRE = () => {
  const { userDataFetched, setUserDataFetched, utilityData } = useContext(UserContext);
  return (
    <div className='mt-3'>
      <div className="row apt-test-form">
        <div className="col-12">
          <hr />
        </div>
        <div className="col-4 mt-1">
          <label className='py-2'><b>Verbal</b></label>
          <OutlinedInput
            type='number'
            id="apt-verbal-score"
            endAdornment={<InputAdornment position="end">/{utilityData.gre.verbal}</InputAdornment>}
            aria-describedby="apt-verbal-score-helper-text"
            inputProps={{
              'aria-label': 'apt-verbal-score',
            }}
            className='w-100'
            size='small'
            value={userDataFetched['aboutDetails']['aptitudeTestDetails']['gre']['verbalScore']}
            onChange={(e) => {
              const updatedUserData = { ...userDataFetched };
              updatedUserData.aboutDetails.aptitudeTestDetails.gre.verbalScore = parseInt(e.target.value, 10);
              updatedUserData.aboutDetails.aptitudeTestDetails.notTaken = false;
              setUserDataFetched(updatedUserData);
            }}
          />
        </div>
        <div className="col-4 mt-1">
          <label className='py-2'><b>Quants</b></label>
          <OutlinedInput
            type='number'
            id="apt-verbal-score"
            endAdornment={<InputAdornment position="end">/{utilityData.gre.quants}</InputAdornment>}
            aria-describedby="apt-verbal-score-helper-text"
            inputProps={{
              'aria-label': 'apt-verbal-score',
            }}
            className='w-100'
            size='small'
            value={userDataFetched['aboutDetails']['aptitudeTestDetails']['gre']['quantsScore']}
            onChange={(e) => {
              const updatedUserData = { ...userDataFetched };
              updatedUserData.aboutDetails.aptitudeTestDetails.gre.quantsScore = parseInt(e.target.value, 10);
              updatedUserData.aboutDetails.aptitudeTestDetails.notTaken = false;
              setUserDataFetched(updatedUserData);
            }}
          />
        </div>
        <div className="col-4 mt-1">
          <label className='py-2'><b>AWA</b></label>
          <OutlinedInput
            type='number'
            id="apt-verbal-score"
            endAdornment={<InputAdornment position="end">/{utilityData.gre.awa}</InputAdornment>}
            aria-describedby="apt-verbal-score-helper-text"
            inputProps={{
              'aria-label': 'apt-verbal-score',
            }}
            className='w-100'
            size='small'
            value={userDataFetched['aboutDetails']['aptitudeTestDetails']['gre']['awaScore']}
            onChange={(e) => {
              const updatedUserData = { ...userDataFetched };
              updatedUserData.aboutDetails.aptitudeTestDetails.gre.awaScore = parseInt(e.target.value, 10);
              updatedUserData.aboutDetails.aptitudeTestDetails.notTaken = false;
              setUserDataFetched(updatedUserData);
            }}
          />
        </div>
        <FormHelperText className='eng-helper-text'>
          <span className="material-symbols-outlined mr-1">
            info
          </span> Enter the final score or the score you're expecting
        </FormHelperText>
        <div className="col-md-6 mt-3">
          <label className='pt-2'><b>Date you appeared for the test</b><span className="text-muted"> (Optional)</span></label>
          <OutlinedInput
            type="date"
            id="apt-exam-date"
            size='small'
            value={userDataFetched['aboutDetails']['aptitudeTestDetails']['gre']['dateTaken']}
            onChange={(e) => {
              const updatedUserData = { ...userDataFetched };
              updatedUserData.aboutDetails.aptitudeTestDetails.gre.dateTaken = e.target.value;
              updatedUserData.aboutDetails.aptitudeTestDetails.notTaken = false;
              setUserDataFetched(updatedUserData);
            }}
            className='w-100' />
        </div>
      </div>
    </div>
  )
}

export default DashboardAptGRE
