import React, { useContext } from 'react';
import { TextField } from '@material-ui/core';
import '../../css/Signup/SignUpForm.css';
import { multiStepContext } from './SignupStepContext';

const SignupForm6 = () => {
  const { setStep, userData, setUserData, checked, setChecked, handleSignUp } = useContext(multiStepContext);
  return (
    <div className='singunp-step-form'>
      <div className="row form-field-row mx-3">
        <h3 className='text-center mt-5'>Create Account</h3>
        <div className="form-check tnc-row mt-4">
          <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" checked={checked} onChange={(e) =>setChecked(e.target.checked)}/>
        </div>
        <label className="form-check-label text-center" for="flexCheckDefault">
          I Agree to the Terms and Conditions
        </label>
        <button type='button' className='btn btn-success mx-2 mt-4 mb-2' onClick={handleSignUp} disabled={!checked}>Create Account</button>
        <button type='button' className='btn btn-primary mx-2 mb-4' onClick={() => { setStep(5) }}>Back</button>
      </div>
    </div>
  )
}

export default SignupForm6
