import React, { useContext } from 'react';
import { TextField, Select, MenuItem } from '@material-ui/core';
import '../../css/Signup/SignUpForm.css';
import { multiStepContext } from './SignupStepContext';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

const SignupForm3 = () => {
  const { setStep, userData, setUserData, confirmPassword, setConfirmPassword } = useContext(multiStepContext);
  return (
    <div className='singunp-step-form'>
      <div className="row form-field-row mx-3">

        {/* Mobile Number */}
        <label className='mt-5'><b>Mobile</b></label>
        <PhoneInput
          country="in"
          className='row'
          value={userData['phonenum']}
          onlyCountries={['in', 'us']}
          inputStyle={{ height: '50px' }}
          onChange={(phonenum) => setUserData({ ...userData, "phonenum": phonenum })}
        />

        {/* Password */}
        <TextField
          label="Password"
          className='mt-4 form-input-field'
          type="password"
          variant="outlined"
          size="small"
          value={userData['password']}
          onChange={(e) => setUserData({ ...userData, "password": e.target.value })}
          style={{ width: '100%' }}
          required
        />

        {/* Confirm Password */}
        <TextField
          label="Confirm Password"
          className='my-3 form-input-field'
          type="password"
          variant="outlined"
          size="small"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          required
        />

        {/* Checking Password and Confirm Password field */}
        {(confirmPassword !== userData['password']) && (userData['password'] !== '') && (confirmPassword !== '') && <p className='text-danger d-flex align-items-center'><span className="material-symbols-outlined">close </span> Password do not match</p>}
        {(confirmPassword === userData['password']) && (userData['password'] !== '') && (confirmPassword !== '') && <p className='text-success d-flex align-items-center'><span className="material-symbols-outlined">check </span> Password Matches</p>}

        {/* Where did you hear about us? */}
        <label className='mt-3'><b>Where did you hear about us?</b></label>
        <Select
          id="select"
          value={userData['hear_about_us']}
          label="Where did you hear about us?"
          onChange={(e) => setUserData({ ...userData, "hear_about_us": e.target.value })}
        >
          <MenuItem value="option1">Option 1</MenuItem>
          <MenuItem value="option2">Option 2</MenuItem>
          <MenuItem value="option3">Option 3</MenuItem>
        </Select>

        {/* Next and Back button rows */}
        <div className="row signup-form-btn-row my-4">
          {/* Back Button */}
          <button type='button' className='btn btn-primary col-3 mx-2' onClick={() => { setStep(2) }}>Back</button>
          {/* Next Button */}
          <button type='button' className='btn btn-success col-3' onClick={() => { setStep(4) }} disabled={(!userData['phonenum']) || (!userData['password']) || (confirmPassword !== userData['password']) || (!userData['hear_about_us'])}>Next</button>
        </div>
      </div>
    </div>
  )
}

export default SignupForm3
