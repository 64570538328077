import React from 'react'

const University = () => {
  return (
    <div>
      Universities
    </div>
  )
}

export default University
