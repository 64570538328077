import React, { useState } from 'react';
import UserProfileDashboard from '../../pages/UserProfileDashboard';
import axios from 'axios';
import Swal from 'sweetalert2';

export const UserContext = React.createContext();
const UserProfileContext = () => {
    const [userDataFetched, setUserDataFetched] = useState('');
    // let fixedUserData = '';
    const [utilityData, setUtilityData] = useState('');
    const [responseStatus, setResponseStatus] = useState('');
    const [loading, setLoading] = useState(true);
    const handleLogout = () => {
        localStorage.setItem('token', null);
        window.location.href = "/";
    }

    const [currentDashboardStep, setDashboardStep] = useState(1);
    const [currentEnglishStep, setEnglishStep] = useState(1);
    const [currentAptitudeStep, setAptitudeStep] = useState(1);

    const [selectedGradYear, setSelectedGradYear] = useState();
    const [selectedPrefCountry, setSelectedPrefCountry] = useState();
    const [showWorkExpForm, setWorkExpForm] = useState(true);
    const jobNature = [
        'Full Time',
        'Part Time',
        'Internship'
    ]
    const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    const currentYear = new Date().getFullYear();
    const years = Array.from({ length: 40 }, (_, index) => currentYear - 20 + index);
    const [isResearchDone, setResearchDone] = useState(true);
    const publicationLevels = [
        'Local',
        'National',
        'International'
    ]
    const prefCountries = [{
        label: 'USA',
        value: 'USA',
        id: 'US'
    },
    {
        label: 'UK',
        value: 'UK',
        id: 'UK'
    },
    {
        label: 'Australia',
        value: 'Australia',
        id: 'AU'
    },
    {
        label: 'Germany',
        value: 'Germany',
        id: 'DE'
    },
    {
        label: 'Canada',
        value: 'Canada',
        id: 'CA'
    },
    {
        label: 'France',
        value: 'France',
        id: 'FR'
    },
    {
        label: 'Ireland',
        value: 'Ireland',
        id: 'IE'
    },
    {
        label: 'Netherlands',
        value: 'Netherlands',
        id: 'NL'
    },
    {
        label: 'Switzerland',
        value: 'Switzerland',
        id: 'CH'
    },
    {
        label: 'Singapore',
        value: 'Singapore',
        id: 'SG'
    }]
    const saveUserData = () => {
        const swalLoad = Swal.fire({
            title: 'Saving progress',
            html: 'Please wait...',
            allowEscapeKey: false,
            allowOutsideClick: false,
            didOpen: () => {
                Swal.showLoading()
            }
        });
        // console.log(userDataFetched);
        const token = localStorage.getItem('token');
        // console.log(token)
        const headers = {
            'Authorization': 'Bearer ' + token
        };
        axios.post("https://zyeero-server.onrender.com/api/userActions/updateUser", userDataFetched, { headers })
            .then(response => {
                localStorage.setItem('fixedUserData', JSON.stringify(response.data));
                // console.log(JSON.parse(localStorage.getItem('fixedUserData')));
                // console.log(_isEqual(JSON.parse(localStorage.getItem('fixedUserData')), userDataFetched));
                swalLoad.close();
                // window.location.reload();
                // console.log(response);
                setResponseStatus(response.status);
                setUserDataFetched(response.data);
                console.log(response.data);
                // setFixedUserData(response.data);
                // console.log(responseStatus);
            })
            .catch(error => {
                console.log(error.response);
            })
    }
    return (
        <>
            <UserContext.Provider
                value={{
                    userDataFetched,
                    setUserDataFetched,
                    utilityData,
                    setUtilityData,
                    responseStatus,
                    setResponseStatus,
                    loading,
                    setLoading,
                    handleLogout,
                    currentDashboardStep,
                    setDashboardStep,
                    currentEnglishStep,
                    setEnglishStep,
                    currentAptitudeStep,
                    setAptitudeStep,
                    selectedGradYear,
                    setSelectedGradYear,
                    selectedPrefCountry,
                    setSelectedPrefCountry,
                    showWorkExpForm,
                    setWorkExpForm,
                    jobNature,
                    months,
                    years,
                    isResearchDone,
                    setResearchDone,
                    publicationLevels,
                    prefCountries,
                    saveUserData
                }}>
                <UserProfileDashboard />
            </UserContext.Provider>
        </>
    )
}

export default UserProfileContext
